import React from "react";
import InfoTemplate from "../components/InfoTemplate";

const AllergenInfo = () => {
  return (
    <InfoTemplate
      title="ALLERGEN INFO"
      images={[
        "/img/info/allergen-info/SOMISOMI_Allergens 1.png",
        "/img/info/allergen-info/SOMISOMI_Allergens 2.png",
      ]}
    />
  );
};

export default AllergenInfo;
