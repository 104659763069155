import React from "react";
import InfoTemplate from "../components/InfoTemplate";

const NutritionFacts = () => {
  return (
    <InfoTemplate
      title="NUTRITION FACTS"
      images={[
        "/img/info/nutrition-facts/SOMISOMI_Nutrition Facts 1.png",
        "/img/info/nutrition-facts/SOMISOMI_Nutrition Facts 2.png",
      ]}
    />
  );
};

export default NutritionFacts;
